<template>
	<div style="padding: 24rem;">
		<el-button @click="onDownload" size="small" style="height: 32rem;margin-right: 24rem">新高考预选科调查结果导出</el-button>
		<div style="font-size: 12rem;color: red;margin-top: 12rem">注：此工具用于导出已参与新高考预选科调查问卷学生的预选意向结果导出</div>
	</div>
</template>

<script>
export default {
	_config:{"route":{"path":"list","meta":{"title":"列表"}}},
  methods: {
		onDownload() {
			this.$_axios2.get('api/tool/download').then(res => {
				const filename = '新高考预选科调查' + '.csv';
				const blob = new Blob([res.data]);
				let downloadElement = document.createElement('a');
				let href = window.URL.createObjectURL(blob);
				downloadElement.href = href;
				downloadElement.download = decodeURIComponent(filename);
				document.body.appendChild(downloadElement);
				downloadElement.click();
				document.body.removeChild(downloadElement);
				window.URL.revokeObjectURL(href);
			});
		},
	},
};
</script>

<style scoped>

</style>
